import React from "react";

import { Container, Balls } from "./styles";

const Separator = ({ children, desktop, decor }) => {
  return (
    <Container desktop={desktop} decor={decor}>
      {children}
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      {/*  */}
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
      <Balls />
    </Container>
  );
};

export default Separator;
