import styled from "styled-components";

export const Container = styled.div`
  background-color: #ffb800;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
