import React from "react";
import { Container } from "./styles.js";
const Footer = () => {
  return (
    <Container>
      <p>Cesar Martinez</p>
    </Container>
  );
};

export default Footer;
